import { IRegionApiGouv } from "@/type";

export async function getAllRegions() {
    try {
        const response = await fetch("https://geo.api.gouv.fr/regions", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        const regions: IRegionApiGouv[] = await response.json();
        regions.sort((a, b) => a.nom.localeCompare(b.nom));
        return regions;
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/regions : ${error}`);
    }
}

export async function getAllDepartements() {
    try {
        const response = await fetch("https://geo.api.gouv.fr/departements", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/departements : ${error}`);
    }
}

export async function getCommunes(query: string) {
    try {
        const url = `https://geo.api.gouv.fr/communes?${isNaN(Number(query)) ? 'nom=' : 'codePostal='}${query}&fields=nom,codesPostaux,departement`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/communes : ${error}`);
    }
}

export async function getCommune(codePostal: string, asJson: boolean = true) {
    try {
        const response = await fetch(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (asJson) {
            return response.json()
        } else {
            return await response.text()
        }
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/communes?codePostal=${codePostal} : ${error}`);
    }
}

export async function getDepartementByCode(codePostal: string) {
    try {
        const response = await fetch(`https://geo.api.gouv.fr/departements/${codePostal}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/departements/${codePostal} : ${error}`);
    }
}

export async function getCommunesByDepartement(codeDepartement: string) {
    try {
        const response = await fetch(`https://geo.api.gouv.fr/departements/${codeDepartement}/communes`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return response.json()
    } catch (error) {
        throw new Error(`Erreur API https://geo.api.gouv.fr/departements/${codeDepartement}/communes : ${error}`);
    }
}
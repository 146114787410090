'use client'
import { ISearchVente } from '@/type'
import dynamic from 'next/dynamic';
import { Prisma } from '@prisma/client';

const Select = dynamic(() => import('react-select'), { ssr: false });

type Props = {
    tgis?: Prisma.TgiGetPayload<{}>[]
    regions: Prisma.RegionsGetPayload<{}>[]
    departements: Prisma.DepartementsGetPayload<{}>[]
    typesBiens: Prisma.VentesTypeGetPayload<{}>[]
    criteriaFields: ISearchVente
    setCriteriaFields: React.Dispatch<React.SetStateAction<ISearchVente>>
}

const AlertCriteria = ({ tgis, regions, departements, typesBiens, criteriaFields, setCriteriaFields }: Props) => {


    return (
        <>
            <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3">
                <div className="w-full lg:w-1/3">
                    <Select
                        className="basic-single"
                        placeholder="Tribunal Judiciaire"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                                zIndex: 9999,
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                                zIndex: 9999,
                            }),
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 9999,
                            }),
                        }}
                        menuPosition="fixed"
                        options={tgis}
                        getOptionLabel={(option: any) => {
                            const ville = option.ville;
                            const prefix = /^[aeiouyAEIOUY]/.test(ville) ? "d'" : "de ";
                            return `Tribunal ${prefix}${ville}`;
                        }}
                        getOptionValue={(option: any) => option.id!.toString()}
                        noOptionsMessage={() => "Aucun TJI correspondant"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            setCriteriaFields({ ...criteriaFields, tgi: selectedOption?.id ?? null })
                        }}
                        value={tgis?.find((tgi) => tgi.id === criteriaFields.tgi)}
                    />
                </div>
                <div className="w-full lg:w-1/3">
                    <Select
                        className="basic-single"
                        placeholder="Régions"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 9999,
                            }),
                        }}
                        menuPosition="fixed"
                        options={regions}
                        getOptionLabel={(option: any) => option ? `${option?.nom}` : ''}
                        getOptionValue={(option: any) => option?.id?.toString()}
                        noOptionsMessage={() => "Aucune région correspondante"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            setCriteriaFields({
                                ...criteriaFields,
                                region: selectedOption ? selectedOption.id.toString() : null,
                                departement: null
                            })
                        }}
                        value={regions.find(option => option.id.toString() === criteriaFields.region)}
                    />
                </div>
                <div className="w-full lg:w-1/3">
                    <Select
                        className="basic-single"
                        placeholder="Département"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 9999,
                            }),
                        }}
                        menuPosition="fixed"
                        options={criteriaFields.region ? departements.filter((dept) => dept.regionId?.toString() === criteriaFields.region) : departements}
                        getOptionLabel={(option: any) => option ? `${option?.cp} - ${option?.nom}` : ''}
                        getOptionValue={(option: any) => option?.cp?.toString()}
                        noOptionsMessage={() => "Aucun département correspondant"}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption: any) => {
                            if (selectedOption?.cp) {
                                setCriteriaFields({ ...criteriaFields, departement: selectedOption?.cp ?? null, region: regions.find((region) => region.id === selectedOption?.regionId)?.id.toString() ?? null })
                            }else {
                                setCriteriaFields({ ...criteriaFields, departement: null })
                            }
                            
                        }}
                        value={departements.find((departement) => departement.cp === criteriaFields.departement) ?? null}
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3 my-3">
                <div className="w-full lg:w-1/3">
                    <Select
                        placeholder="Type de bien"
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            container: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                width: '100%',
                            }),
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 9999,
                            }),
                        }}
                        menuPosition="fixed"
                        options={typesBiens}
                        getOptionLabel={(option: any) => `${option.label}`}
                        getOptionValue={(option: any) => option.id!.toString()}
                        noOptionsMessage={() => "Aucun type correspondant"}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={true}
                        onChange={(selectedOptions: any) => {
                            setCriteriaFields({ ...criteriaFields, typesBiens: selectedOptions ?? [] })
                        }}
                        value={typesBiens.filter((typeBiens) => criteriaFields.typesBiens?.find((selectedOption) => selectedOption.id === typeBiens.id))}
                    />
                </div>
                <div className="relative flex align-items-center w-full lg:w-1/3">
                    <input
                        type="text"
                        id="prix"
                        name="prix"
                        placeholder="Prix maximum"
                        className="w-full px-2 py-[8px] text-sm grow rounded-md border border-zinc-300 bg-white text-zinc-700 focus:border-zinc-500 focus:ring-zinc-500 focus:ring-1 duration-700 hover:border-zinc-500 hover:ring-zinc-500 hover:ring-1"
                        onChange={(e) => {
                            const val = e.target.value;
                            if (/^\d+$/.test(val) || val === '') {
                                setCriteriaFields({ ...criteriaFields, prixMax: val });
                            }
                        }}
                        value={criteriaFields.prixMax ?? ''}
                    />
                    {criteriaFields.prixMax && (
                        <button
                            type="button"
                            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-zinc-700 hover:text-zinc-500"
                            onClick={() => setCriteriaFields({ ...criteriaFields, prixMax: '' })}
                        >
                            &#x2715; {/* Croix */}
                        </button>
                    )}
                </div>
                <div className="w-full lg:w-1/3"></div>
            </div>
        </>
    )
}

export default AlertCriteria